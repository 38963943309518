import PropTypes from 'prop-types';

import { TrashScaleSmall, PenSquareScaleSmall, CameraScaleSmall, IconColor } from 'bloko/blocks/icon';
import Text, { TextSize } from 'bloko/blocks/text';

const SidebarItem = ({ children, number, onUpload, onEdit, onRemove }) => (
    <div className={'widget-gallery-item'}>
        <div className={'widget-gallery-item__number'}>
            <Text size={TextSize.Small}>{number}</Text>
        </div>
        <div className={'widget-gallery-item__image'}>{children}</div>
        <div className={'widget-gallery-item__actions'}>
            <label>
                <span className={'widget-gallery-item__button'}>
                    <CameraScaleSmall initial={IconColor.Gray50} />
                    <input data-qa="gallery-widget-file-input" type="file" className="g-hidden" onChange={onUpload} />
                </span>
            </label>
            <button data-qa={'gallery-widget-button-edit'} className={'widget-gallery-item__button'} onClick={onEdit}>
                <PenSquareScaleSmall initial={IconColor.Gray50} />
            </button>
            <button
                data-qa={'gallery-widget-button-remove'}
                className={'widget-gallery-item__button'}
                onClick={onRemove}
            >
                <TrashScaleSmall initial={IconColor.Gray50} />
            </button>
        </div>
    </div>
);

SidebarItem.propTypes = {
    number: PropTypes.number,
    children: PropTypes.node,
    onUpload: PropTypes.func,
    onEdit: PropTypes.func,
    onRemove: PropTypes.func,
};

export default SidebarItem;
