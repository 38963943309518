import { useCallback, useMemo, useState } from 'react';

const useImageCrop = () => {
    const [visible, setVisible] = useState(false);
    const [typedPictureId, setTypesPictureId] = useState(null);
    const [pictureId, setPictureId] = useState(null);

    const props = useMemo(
        () => ({
            pictureId,
            typedPictureId,
            visible,
            setVisible,
            onClose: () => {
                setVisible(false);
                setPictureId(null);
                setTypesPictureId(null);
            },
        }),
        [pictureId, typedPictureId, visible]
    );

    const cropPictureById = useCallback((pictureId, typedPictureId = null) => {
        setPictureId(pictureId);
        setTypesPictureId(typedPictureId);
        setVisible(true);
    }, []);

    return [props, cropPictureById];
};

export default useImageCrop;
