import PropTypes from 'prop-types';

import { ChevronScaleSmallKindRight, ChevronScaleSmallKindLeft } from 'bloko/blocks/icon';

const Switcher = ({ count, value, onChange }) => (
    <div className="widget-gallery-switcher">
        <button
            data-qa="widget-gallery-switcher-button-left"
            className="widget-gallery-switcher-button"
            onClick={() => onChange(value <= 1 ? count : value - 1)}
        >
            <ChevronScaleSmallKindLeft initial="inverted" />
        </button>
        <span>
            {value} / {count}
        </span>
        <button
            data-qa="widget-gallery-switcher-button-right"
            className="widget-gallery-switcher-button"
            onClick={() => onChange(value >= count ? 1 : value + 1)}
        >
            <ChevronScaleSmallKindRight initial="inverted" />
        </button>
    </div>
);

Switcher.propTypes = {
    count: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func,
};

export default Switcher;
