import PropTypes from 'prop-types';

export default PropTypes.shape({
    pictureId: PropTypes.number,
    originalPath: PropTypes.string,
    resizedSmallPath: PropTypes.string,
    path: PropTypes.string,
    originalWidth: PropTypes.number,
    originalHeight: PropTypes.number,
    selectionLeft: PropTypes.number,
    selectionTop: PropTypes.number,
    selectionWidth: PropTypes.number,
    selectionHeight: PropTypes.number,
});
