import { FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { AxiosError } from 'axios';

import {
    BottomSheet,
    BottomSheetFooter,
    Button,
    Modal,
    NavigationBar,
    useBreakpoint,
    VSpacingContainer,
    Action,
    ActionBar,
} from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import pointProvider from 'HH/Maps/PointProvider';
import utils, { AddressFromYandexData } from 'Modules/Maps/Utils';
import { GeoObjectsContext, YMaps, YMapsInstance } from 'Modules/Maps/maps.types';
import translation from 'src/components/translation';
import fetcher from 'src/utils/fetcher';

import AddressBalloon from 'src/components/AddressSuggest/AddressBallon';
import AddressMap from 'src/components/AddressSuggest/AddressMap';
import AddressModalContentWrapper from 'src/components/AddressSuggest/AddressModalContentWrapper';
import AddressModalSidebar from 'src/components/AddressSuggest/AddressModalSidebar';
import AddressSavingError, { ALREADY_EXIST, METRO_TOO_MANY } from 'src/components/AddressSuggest/AddressSavingError';
import AddressSearchForm from 'src/components/AddressSuggest/AddressSearchForm';
import AddressSearchResults from 'src/components/AddressSuggest/AddressSearchResults';
import AddressSelectedFromSearch from 'src/components/AddressSuggest/AddressSelectedFromSearch';
import {
    AddressState,
    AddressResponse,
    FormValues,
    MapData,
    Metro,
    MetroStation,
    YandexCity,
} from 'src/components/AddressSuggest/types';
import { createYandexMaps, prepareMetroItem } from 'src/components/AddressSuggest/utils';

import styles from './add-address-modal.less';

const TrlKeys = {
    addTitle: 'employer.address.edit.title.add',
    editTitle: 'employer.address.edit.title.edit',
    editSearch: 'employer.address.edit.search',
    placeholder: 'employer.address.edit.search.placeholder',
    editAddress: 'employer.address.edit.address',
    editDescription: 'employer.address.edit.description',
    buttonAdd: 'employer.address.edit.button.add',
    buttonReset: 'employer.address.edit.reset',
    buttonEdit: 'employer.address.edit.button.edit',
    useExisting: 'employer.address.use.existing',
    showExisting: 'employer.address.show.existing',
    addressAlreadyExist: 'employer.address.edit.address.alreadyExists',
    metroTooMany: 'employer.address.edit.address.metroTooMany',
};

const RESULTS_COUNT = 20;
const DEFAULT_ZOOM = 10;
const ZOOM_MARGIN = 10;

// Центр Москвы
const DEFAULT_LAT = 55.76;
const DEFAULT_LNG = 37.64;

const ADDRESS_EDIT_URL = '/employer/addresses/edit';
const ADDRESS_VIEW_URL = '/employer/addresses/view_deprecated';

declare global {
    interface FetcherPostApi {
        [ADDRESS_EDIT_URL]: {
            queryParams: void;
            body: FormValues;
            response: {
                result: string;
            };
        };
    }

    interface FetcherGetApi {
        [ADDRESS_VIEW_URL]: {
            queryParams: { id: number };
            response: AddressResponse;
        };
    }
}

interface AddAddressModalContentProps {
    visible?: boolean;
    employerManagerId?: string;
    onAddressCreate: (address: AddressFromYandexData) => void;
    onAddressSelect: (address: { id?: number }) => void;
    onAddressUpdate: (address: AddressFromYandexData) => void;
    onAddressDelete: (address: { id?: string }) => void;
    onModalClose: () => void;
    duplicate: 'show' | 'use';
    addressId?: number;
}

const AddAddressModalContent: TranslatedComponent<AddAddressModalContentProps> = ({
    trls,
    visible = false,
    employerManagerId,
    onAddressCreate,
    onAddressSelect,
    onAddressUpdate,
    onModalClose,
    addressId,
    duplicate,
}) => {
    const [value, setValue] = useState('');
    const [lastValue, setLastValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [canEdit, setCanEdit] = useState(true);
    const [fullAddress, setFullAddress] = useState('');
    const [city, setCity] = useState('');
    const [description, setDescription] = useState('');
    const [savedError, setSavedError] = useState('');
    const [currentAddressId, setCurrentAddressId] = useState(addressId);
    const [savedAddressId, setSavedAddressId] = useState('');
    const [coordinates, setCoordinates] = useState<number[]>([0, 0]);
    const [modalContentMaxHeight, setModalContentMaxHeight] = useState<number>(500);
    const [modalSidebarAndMapHeight, setModalSidebarAndMapHeight] = useState<number>(400);
    const addressSearchRef = useRef<HTMLDivElement>(null);
    const currentGeoObjectsContextRef = useRef<GeoObjectsContext>();
    const mapContainer = useRef<HTMLDivElement>(null);
    const ymapsInstance = useRef<YMaps | null>(null);
    const yandexMapInstance = useRef<YMapsInstance | null>(null);
    const [cities, setCities] = useState<YandexCity[]>([]);
    const [addressState, setAddressState] = useState(currentAddressId ? AddressState.Address : AddressState.Prepared);
    const [selectedMetros, setSelectedMetros] = useState<Metro[]>([]);
    const [currentSelectedMetro, setCurrentSelectedMetro] = useState<MetroStation[]>([]);
    const { isMobile } = useBreakpoint();

    const updateMap = useCallback((geoObjects: GeoObjectsContext, draggable = false) => {
        if (ymapsInstance.current && yandexMapInstance.current) {
            yandexMapInstance.current.geoObjects.removeAll();

            const geoQuery = ymapsInstance.current?.geoQuery(geoObjects);
            const html = renderToString(<AddressBalloon />);
            geoQuery
                .setOptions('balloonContentLayout', ymapsInstance.current.templateLayoutFactory.createClass(html))
                .setOptions('draggable', draggable)
                .addToMap(yandexMapInstance.current);
            yandexMapInstance.current.setBounds(geoQuery.getBounds(), {
                checkZoomRange: true,
                zoomMargin: ZOOM_MARGIN,
            });
        }
    }, []);

    const getMapInstance = useCallback(async () => {
        if (mapContainer.current && !currentAddressId && ymapsInstance.current === null) {
            const { ymaps, mapInstance } = await createYandexMaps(
                mapContainer.current,
                [DEFAULT_LAT, DEFAULT_LNG],
                DEFAULT_ZOOM
            );
            ymapsInstance.current = ymaps;
            yandexMapInstance.current = mapInstance;
            setAddressState(AddressState.Init);
        }

        if (mapContainer.current && currentAddressId) {
            const response = await fetcher.get('/employer/addresses/view_deprecated', {
                params: { id: currentAddressId },
            });

            let center: number[] = [DEFAULT_LAT, DEFAULT_LNG];
            let zoom: number = DEFAULT_ZOOM;
            if (response.mapData) {
                try {
                    const mapData = JSON.parse(response.mapData) as MapData;
                    center = [mapData.points.center.lat, mapData.points.center.lng];
                    zoom = mapData.points.center.zoom;
                    // eslint-disable-next-line no-empty
                } catch (_) {} // not critical, we have fall-backs
            }

            if (ymapsInstance.current === null) {
                const { ymaps, mapInstance } = await createYandexMaps(mapContainer.current, center, zoom);
                ymapsInstance.current = ymaps;
                yandexMapInstance.current = mapInstance;
            }

            const metro = response.metroStations?.metro || [];
            const geoObjectContext = utils.geoObjectFromAddress(response, ymapsInstance.current);

            setCurrentSelectedMetro(metro);
            setFullAddress(response.displayName);
            setValue(response.displayName);
            setDescription(response.description || '');
            setCanEdit(response['@canEdit']);
            setCity(response.city);
            setCoordinates([response.marker['@lat'], response.marker['@lng']]);
            setAddressState(AddressState.Address);
            setSelectedMetros(metro.map(prepareMetroItem));

            if (ymapsInstance.current !== null) {
                const collection = new ymapsInstance.current.GeoObjectCollection();

                metro.forEach(({ lat, lng }) => {
                    if (ymapsInstance.current) {
                        const placemark = new ymapsInstance.current.Placemark(
                            [lat, lng],
                            {},
                            {
                                visible: false,
                            }
                        );
                        collection.add(placemark);
                    }
                });
                collection.add(geoObjectContext);

                currentGeoObjectsContextRef.current = geoObjectContext;
                updateMap(collection, response['@canEdit']);
            }
        }
    }, [currentAddressId, updateMap]);

    const handleSearchAddress = async (event: FormEvent) => {
        event.preventDefault();
        event.stopPropagation();
        if (!value || value === lastValue || isLoading) {
            return;
        }
        setLastValue(value);

        if (ymapsInstance.current) {
            setIsLoading(true);
            setAddressState(AddressState.Search);
            let response;
            try {
                response = await ymapsInstance.current.geocode(value, {
                    results: RESULTS_COUNT,
                    provider: pointProvider,
                });
            } catch (e) {
                console.error(e);
                setIsLoading(false);
                return;
            } finally {
                setIsLoading(false);
            }

            const yandexCities: YandexCity[] = [];
            response.geoObjects.each((context) => {
                const flat = utils.convertGeoObject(context);
                let fullAddress = utils.join([flat.LocalityName, utils.getStreet(flat), utils.getBuilding(flat)]);
                fullAddress = fullAddress || flat.AddressLine;
                const coordinates = context.geometry.getCoordinates();

                yandexCities.push({
                    address: context.properties.get('name'),
                    city: context.properties.get('description'),
                    fullAddress,
                    context,
                    coordinates,
                    localityName: flat.LocalityName,
                });
            });
            setCities(yandexCities);
            updateMap(response.geoObjects);
        }
    };

    const handleCreateOrUpdateAddress = async () => {
        const data = utils.addressFromYandex(
            currentGeoObjectsContextRef.current,
            yandexMapInstance.current,
            selectedMetros,
            description,
            currentAddressId
        );
        if (employerManagerId) {
            data.employerManagerId = employerManagerId;
        }

        let response;
        try {
            response = await fetcher.postFormData(ADDRESS_EDIT_URL, data);
            data.id = currentAddressId ? String(currentAddressId) : response.data.result;
            const fn = currentAddressId ? onAddressUpdate : onAddressCreate;

            fn(data);
            onModalClose();
        } catch (e) {
            const axiosError = e as AxiosError<{
                errors?: { reason: string; id: string }[];
            }>;
            axiosError.response?.data?.errors?.forEach((error) => {
                if ([ALREADY_EXIST, METRO_TOO_MANY].includes(error.reason)) {
                    setSavedError(error.reason);
                    setSavedAddressId(error.id);
                }
            });
        }
    };

    useEffect(() => {
        yandexMapInstance.current?.container.fitToViewport();
    }, [addressState, modalSidebarAndMapHeight]);

    useEffect(() => {
        const documentRoot = document.querySelector(':root') as HTMLElement;
        if (addressSearchRef.current && documentRoot) {
            const addressSearchHeight = addressSearchRef.current.clientHeight;
            const sidebarAndMapHeight = modalContentMaxHeight - addressSearchHeight;
            setModalSidebarAndMapHeight(modalContentMaxHeight - addressSearchHeight);
            documentRoot.style.setProperty('--modal-sidebar-and-map-height', `${sidebarAndMapHeight}px`);
        }
    }, [modalContentMaxHeight]);

    const modalAddressSearch = (
        <AddressSearchForm ref={addressSearchRef} value={value} setValue={setValue} onSubmit={handleSearchAddress} />
    );

    const modalSidebarSearchResults = (
        <AddressSearchResults
            isLoading={isLoading}
            addressState={addressState}
            cities={cities}
            currentGeoObjectsContextRef={currentGeoObjectsContextRef}
            updateMap={updateMap}
            setCity={setCity}
            setAddressState={setAddressState}
            setCoordinates={setCoordinates}
            setFullAddress={setFullAddress}
        />
    );

    const modalSidebarAddressSelected = (
        <AddressSelectedFromSearch
            addressState={addressState}
            city={city}
            fullAddress={fullAddress}
            currentSelectedMetro={currentSelectedMetro}
            coordinates={coordinates}
            ymapsInstance={ymapsInstance}
            getMapInstance={getMapInstance}
            currentGeoObjectsContextRef={currentGeoObjectsContextRef}
            setSelectedMetros={setSelectedMetros}
            description={description}
            setDescription={setDescription}
            currentAddressId={currentAddressId}
            updateMap={updateMap}
        />
    );

    const modalSidebarContent = (
        <AddressModalSidebar
            showSidebar={addressState === AddressState.Search || addressState === AddressState.Address}
        >
            {modalSidebarSearchResults}
            {modalSidebarAddressSelected}
        </AddressModalSidebar>
    );

    const modalAddressMap = <AddressMap ref={mapContainer} />;

    const modalContent = (
        <AddressModalContentWrapper visible={visible} setModalContentMaxHeight={setModalContentMaxHeight}>
            {modalAddressSearch}
            {isMobile ? (
                <VSpacingContainer default={24}>
                    {modalAddressMap}
                    {modalSidebarSearchResults}
                    {modalSidebarAddressSelected}
                </VSpacingContainer>
            ) : (
                <div className={styles.addressModalSidebarAndMap}>
                    {modalSidebarContent}
                    {modalAddressMap}
                </div>
            )}
        </AddressModalContentWrapper>
    );

    const modalTitle = trls[currentAddressId ? TrlKeys.editTitle : TrlKeys.addTitle];

    const cancelCreateOrUpdateAddress = (
        <Button
            stretched
            key="action0"
            mode="tertiary"
            style="accent"
            data-qa="address-edit-reset"
            onClick={onModalClose}
        >
            {trls[TrlKeys.buttonReset]}
        </Button>
    );

    const createOrUpdateAddress =
        addressState === AddressState.Address && canEdit ? (
            <Button
                stretched
                key="action1"
                mode="primary"
                style="accent"
                onClick={handleCreateOrUpdateAddress}
                data-qa="address-edit-submit"
            >
                {trls[currentAddressId ? TrlKeys.buttonEdit : TrlKeys.buttonAdd]}
            </Button>
        ) : null;

    return (
        <>
            <AddressSavingError
                duplicate={duplicate}
                savedError={savedError}
                setSavedError={setSavedError}
                onClick={() => {
                    const savedAddressIdNum = Number(savedAddressId);
                    if (duplicate === 'show') {
                        setCurrentAddressId(savedAddressIdNum);
                        setSavedError('');
                    } else {
                        onAddressSelect({ id: savedAddressIdNum });
                        onModalClose();
                    }
                }}
            />
            <Modal
                actions={<Action mode="secondary" onClick={onModalClose} icon={CrossOutlinedSize24} />}
                titleSize="large"
                visible={!isMobile && visible}
                onClose={onModalClose}
                data-qa="add-address-modal"
                title={modalTitle}
                footer={
                    <ActionBar primaryActions={createOrUpdateAddress} secondaryActions={cancelCreateOrUpdateAddress} />
                }
            >
                {modalContent}
            </Modal>
            <BottomSheet
                height="full-screen"
                visible={isMobile && visible}
                onClose={onModalClose}
                header={<NavigationBar title={modalTitle} />}
                footer={<BottomSheetFooter>{[createOrUpdateAddress, cancelCreateOrUpdateAddress]}</BottomSheetFooter>}
            >
                {modalContent}
            </BottomSheet>
        </>
    );
};

export default translation(AddAddressModalContent);
