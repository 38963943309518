import { Children } from 'react';

const AUTO_SCROLL_OFFSET = 100;
const AUTO_SCROLL_SPEED = 3;

export const getChildrenKeys = (children) => {
    return Children.map(children, (child) => {
        return Number(child.key);
    });
};

export const getClientXYOnDragEvents = (event) => {
    let clientX;
    let clientY;
    let pageX;
    let pageY;

    if (['mousemove', 'mousedown'].includes(event.type)) {
        clientX = event.clientX;
        clientY = event.clientY;
        pageX = event.pageX;
        pageY = event.pageY;
    } else {
        clientX = event.touches[0].clientX;
        clientY = event.touches[0].clientY;
        pageX = event.touches[0].pageX;
        pageY = event.touches[0].pageY;
    }

    return { clientX, clientY, pageX, pageY };
};

export const setDragElementPosition = (element, eventPositions, container, offsets) => {
    const containerSizes = container.getBoundingClientRect();
    element.style.left = `${eventPositions.clientX - containerSizes.left - offsets.left}px`;
    element.style.top = `${eventPositions.clientY - containerSizes.top - offsets.top}px`;
};

export const onMove = (
    event,
    {
        dragged,
        dragElements,
        currentDragIndex,
        lastMouseEvent,
        startPageY,
        container,
        clickOffset,
        setDropPositionIndex,
        setMovedUp,
        scrollByContainer = false,
    }
) => {
    if (!dragged.current || !dragElements.current.length || currentDragIndex === null) {
        return;
    }
    lastMouseEvent.current = event;
    const eventPositions = getClientXYOnDragEvents(event);
    const movedUp = eventPositions.pageY < startPageY;
    const currentDragElement = dragElements.current[currentDragIndex];
    setDragElementPosition(currentDragElement, eventPositions, container.current, clickOffset);

    const dragElementBounds = currentDragElement.getBoundingClientRect();

    let collisionElementIndex = null;
    dragElements.current.find((element, index, array) => {
        const currentElementBounds = element?.getBoundingClientRect?.();
        if (!currentElementBounds) {
            return false;
        }
        const prevElementBounds = array[index - 1]?.getBoundingClientRect?.() || { bottom: 0, height: 0 };
        const nextElementBounds = array[index + 1]?.getBoundingClientRect?.() || { top: Infinity, height: 0 };
        const collisionDetected =
            (movedUp &&
                dragElementBounds.top + clickOffset.top < currentElementBounds.top + currentElementBounds.height / 2 &&
                dragElementBounds.top + clickOffset.top > prevElementBounds.bottom - prevElementBounds.height / 2 &&
                array[index - 1] !== currentDragElement) ||
            (!movedUp &&
                dragElementBounds.top + clickOffset.top >
                    currentElementBounds.bottom - currentElementBounds.height / 2 &&
                dragElementBounds.top + clickOffset.top < nextElementBounds.top + nextElementBounds.height / 2 &&
                array[index + 1] !== currentDragElement);

        if (collisionDetected) {
            collisionElementIndex = index;
        }
        return collisionDetected;
    });

    setDropPositionIndex(collisionElementIndex);
    setMovedUp(movedUp);

    let scrollContainer = window;
    let containerHeight = window.innerHeight;
    let mouseTopOffsetPositionContainer = eventPositions.clientY;

    if (scrollByContainer) {
        const containerBounds = container.current.parentElement.getBoundingClientRect();
        scrollContainer = container.current.parentElement;
        containerHeight = containerBounds.height;
        mouseTopOffsetPositionContainer = eventPositions.clientY - containerBounds.y;
    }

    if (mouseTopOffsetPositionContainer < AUTO_SCROLL_OFFSET) {
        scrollContainer.scrollBy(0, -AUTO_SCROLL_SPEED);
    } else if (
        mouseTopOffsetPositionContainer < containerHeight &&
        mouseTopOffsetPositionContainer > containerHeight - AUTO_SCROLL_OFFSET
    ) {
        scrollContainer.scrollBy(0, AUTO_SCROLL_SPEED);
    }
};

export const onDragStart = (
    event,
    {
        index,
        dragged,
        dragElements,
        container,
        setDropPositionIndex,
        setClickOffset,
        setCurrentDragIndex,
        setStartPageY,
    }
) => {
    const eventPositions = getClientXYOnDragEvents(event);
    dragged.current = true;
    const currentDragElement = dragElements.current[index];
    const dragElementBounds = currentDragElement.getBoundingClientRect();
    const offsets = {
        left: eventPositions.clientX - dragElementBounds.left,
        top: eventPositions.clientY - dragElementBounds.top,
    };
    setDragElementPosition(currentDragElement, eventPositions, container.current, offsets);
    setDropPositionIndex(index);
    setClickOffset(offsets);
    setCurrentDragIndex(index);
    setStartPageY(eventPositions.pageY);
};

export const onDragEnd = ({
    dragged,
    currentDragIndex,
    dropPositionIndex,
    onDrop,
    setDropPositionIndex,
    setCurrentDragIndex,
}) => {
    dragged.current = false;
    if (currentDragIndex !== null && dropPositionIndex !== null) {
        onDrop(currentDragIndex, dropPositionIndex);
    }
    setDropPositionIndex(null);
    setCurrentDragIndex(null);
};
