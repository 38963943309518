import PropTypes from 'prop-types';

import Button from 'bloko/blocks/button';
import Loading, { LoadingScale, LoadingColor } from 'bloko/blocks/loading';

const FileLoadButton = ({ inputProps, children, isLoading, disabled, ...buttonProps }) => (
    <label>
        <Button
            {...buttonProps}
            disabled={disabled}
            Element="span"
            loading={isLoading && <Loading initial={LoadingColor.Gray80} scale={LoadingScale.Small} />}
        >
            {!disabled && <input type="file" data-qa="upload-widget-image" className="g-hidden" {...inputProps} />}
            {children}
        </Button>
    </label>
);

FileLoadButton.propTypes = {
    children: PropTypes.node,
    isLoading: PropTypes.bool,
    disabled: PropTypes.bool,
    inputProps: PropTypes.shape({
        accept: PropTypes.array,
        onChange: PropTypes.func,
    }),
};

export default FileLoadButton;
