import PropTypes from 'prop-types';

const ImageSlide = ({ src }) => (
    <div className="widget-gallery-image">
        <img src={src} draggable={false} className="widget-gallery-image__image" />
    </div>
);

ImageSlide.propTypes = {
    src: PropTypes.string.isRequired,
};

export default ImageSlide;
