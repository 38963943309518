import PropTypes from 'prop-types';

import { instanceOfDOMNodeOrNull } from 'bloko/common/helpers/customPropTypes';

const MovedElementStub = ({ dragElement, stubClassName }) => {
    const bounds = dragElement.getBoundingClientRect();
    return <div className={stubClassName} style={{ height: bounds.height }}></div>;
};

MovedElementStub.propTypes = {
    dragElement: instanceOfDOMNodeOrNull(),
    stubClassName: PropTypes.string,
};

export default MovedElementStub;
