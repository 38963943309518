import { FC, PropsWithChildren } from 'react';

const HeightByRatio: FC<PropsWithChildren<{ ratio: number }>> = ({ children, ratio }) => (
    <div
        className="widget-height-by-ratio"
        style={{
            padding: `calc((100% / ${ratio}) / 2) 0`,
        }}
    >
        <div className="widget-height-by-ratio__content">{children}</div>
    </div>
);

export default HeightByRatio;
