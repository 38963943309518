import { createRef, PureComponent } from 'react';
import PropTypes from 'prop-types';

import throttle from 'bloko/common/throttle';

const DEFAULT_AREA_RELATIVE_WIDTH = 0.8;
const DEFAULT_AREA_RELATIVE_HEIGHT = 0.8;
const DEFAULT_AREA_RELATIVE_X = 0.1;
const DEFAULT_AREA_RELATIVE_Y = 0.1;
const MINIMUM_AREA_SIZE_PX = 30;
const MOUSEMOVE_THROTTLE_DELAY_MS = 30;
const CONTAINER_MAXIMUM_HEIGHT_PX = 400;

export default class ImageCrop extends PureComponent {
    static propTypes = {
        /** src оригинала картинки */
        src: PropTypes.string.isRequired,
        /** стартовый X относительно оригинального размера картинки */
        stateX: PropTypes.number.isRequired,
        /** стартовый Y относительно оригинального размера картинки */
        stateY: PropTypes.number.isRequired,
        /** стартовая ширина относительно оригинального размера картинки */
        stateWidth: PropTypes.number.isRequired,
        /** стартовая высота относительно оригинального размера картинки */
        stateHeight: PropTypes.number.isRequired,
        /** ширина оригинала */
        originalWidth: PropTypes.number.isRequired,
        /** высота оригинала */
        originalHeight: PropTypes.number.isRequired,
        /** пропорции размеров выбираемой области */
        ratio: PropTypes.number,
        /** минимальная ширина для выбора области относительно оригинала */
        minimumWidth: PropTypes.number,
        /** минимальная высота для выбора области относительно оригинала */
        minimumHeight: PropTypes.number,
        /** максимальная ширина контейнера. Картинка внутри будет позиционироваться по центру */
        containerMaximumWidth: PropTypes.number,
        /** максимальная высота контейнера. Картинка внутри будет позиционироваться по центру */
        containerMaximumHeight: PropTypes.number,
        /** для возврата получившихся значений */
        onResizeCallback: PropTypes.func,
        onDragStart: PropTypes.func,
        onDragStop: PropTypes.func,
    };

    static defaultProps = {
        src: '',
        stateX: 0,
        stateY: 0,
        stateWidth: 0,
        stateHeight: 0,
        ratio: 0,
        originalWidth: 1,
        originalHeight: 1,
        minimumWidth: MINIMUM_AREA_SIZE_PX,
        minimumHeight: MINIMUM_AREA_SIZE_PX,
        containerMaximumHeight: CONTAINER_MAXIMUM_HEIGHT_PX,
    };

    areaState = { x: 0, y: 0, width: 0, height: 0 };
    containerSize = { width: 0, height: 0 };
    originalToAreaRatio = 1;

    containerElement = createRef();
    areaElement = createRef();
    imageElement = createRef();

    /** определяем пропорции (originalToAreaRatio) оригинал / контейнер */
    imageOnLoad = () => {
        if (this.props.containerMaximumWidth) {
            this.imageElement.current.style.maxWidth = `${this.props.containerMaximumWidth}px`;
        }
        if (this.props.containerMaximumHeight) {
            this.imageElement.current.style.maxHeight = `${this.props.containerMaximumHeight}px`;
        }
        let computedImageHeight = this.imageElement.current.getBoundingClientRect().height;
        this.containerElement.current.style.width = `${Math.floor(this.imageElement.current.offsetWidth)}px`;
        this.containerElement.current.style.height = `${Math.floor(computedImageHeight)}px`;
        /** floor и computedImageHeight рассчитывается заново потому что после изменения размеров контейнера могу измениться размеры картинки внутри, т.к. она контейнерозависимая по max-width и max-height */
        computedImageHeight = this.imageElement.current.getBoundingClientRect().height;
        this.containerSize.width = this.imageElement.current.offsetWidth;
        this.containerSize.height = computedImageHeight;
        this.imageIsLandscape = this.props.originalWidth / this.props.originalHeight > 1;
        this.originalToAreaRatio = this.imageIsLandscape
            ? this.props.originalHeight / this.containerSize.height
            : this.props.originalWidth / this.containerSize.width;
        this.initRelativeZone();
    };

    /** Считает позиционирование с учетом масштабирования от исходного размера картинки */
    initRelativeZone() {
        if (this.props.ratio) {
            this.areaState.width = this.props.stateWidth / this.originalToAreaRatio;
            this.areaState.height = this.props.stateHeight / this.originalToAreaRatio;
            this.areaState.x = this.props.stateX / this.originalToAreaRatio;
            this.areaState.y = this.props.stateY / this.originalToAreaRatio;
        } else {
            this.areaState.width = this.containerSize.width * DEFAULT_AREA_RELATIVE_WIDTH;
            this.areaState.height = this.containerSize.height * DEFAULT_AREA_RELATIVE_HEIGHT;
            this.areaState.x = this.containerSize.width * DEFAULT_AREA_RELATIVE_X;
            this.areaState.y = this.containerSize.height * DEFAULT_AREA_RELATIVE_Y;
        }
        this.setAreaPosition();
    }

    setAreaPosition() {
        this.areaElement.current.style.borderLeftWidth = `${this.areaState.x}px`;
        this.areaElement.current.style.borderTopWidth = `${this.areaState.y}px`;
        this.areaElement.current.style.borderRightWidth = `${
            this.containerSize.width - this.areaState.width - this.areaState.x
        }px`;
        this.areaElement.current.style.borderBottomWidth = `${
            this.containerSize.height - this.areaState.height - this.areaState.y
        }px`;
    }

    dragParams = { clickX: 0, clickY: 0, savedAreaStateX: 0, savedAreaStateY: 0 };

    componentDidMount() {
        document.addEventListener('mouseup', this.clearCurrentSelectElement);
        document.addEventListener('touchend', this.clearCurrentSelectElement);
        document.addEventListener('mousemove', this.throttledDrag);
        document.addEventListener('touchmove', this.throttledDrag);
        this.props.onResizeCallback?.({
            noChanges: true,
        });
    }

    componentWillUnmount() {
        document.removeEventListener('mouseup', this.clearCurrentSelectElement);
        document.removeEventListener('touchend', this.clearCurrentSelectElement);
        document.removeEventListener('mousemove', this.throttledDrag);
        document.removeEventListener('touchmove', this.throttledDrag);
    }
    setAreaStateFunction = null;
    setXYOnDragStart = (event) => {
        this.props.onDragStart?.();

        const { clientX, clientY } = this.getClientXYOnDragEvents(event);

        this.dragParams.clickX = clientX;
        this.dragParams.clickY = clientY;
        this.dragParams.savedAreaStateX = this.areaState.x;
        this.dragParams.savedAreaStateY = this.areaState.y;
        this.dragParams.savedAreaStateWidth = this.areaState.width;
        this.dragParams.savedAreaStateHeight = this.areaState.height;
    };
    clearCurrentSelectElement = () => {
        this.props.onDragStop?.();
        this.setAreaStateFunction = null;
    };
    leftSideIsOutOfBounds = () => this.areaState.x < 0;
    rightSideIsOutOfBounds = () => this.areaState.x + this.areaState.width > this.containerSize.width;
    topSideIsOutOfBounds = () => this.areaState.y < 0;
    bottomSideIsOutOfBounds = () => this.areaState.y + this.areaState.height > this.containerSize.height;
    setAreaStateOnAreaDrag = (deltaX, deltaY) => {
        this.areaState.x = this.dragParams.savedAreaStateX + deltaX;
        this.areaState.y = this.dragParams.savedAreaStateY + deltaY;

        if (this.leftSideIsOutOfBounds()) {
            this.areaState.x = 0;
        }
        if (this.topSideIsOutOfBounds()) {
            this.areaState.y = 0;
        }
        if (this.rightSideIsOutOfBounds()) {
            this.areaState.x = this.containerSize.width - this.areaState.width;
        }
        if (this.bottomSideIsOutOfBounds()) {
            this.areaState.y = this.containerSize.height - this.areaState.height;
        }
    };
    fitAreaState = {
        onRightBottomCornerDrag: {
            runFit: () => {
                if (this.rightSideIsOutOfBounds()) {
                    this.fitAreaState.onRightBottomCornerDrag.onRightSideIsOutOfBounds();
                }
                if (this.bottomSideIsOutOfBounds()) {
                    this.fitAreaState.onRightBottomCornerDrag.onBottomSideIsOutOfBounds();
                }
            },
            onRightSideIsOutOfBounds: () => {
                this.areaState.width = this.containerSize.width - this.areaState.x;
                if (this.props.ratio) {
                    this.areaState.height = this.areaState.width / this.props.ratio;
                }
            },
            onBottomSideIsOutOfBounds: () => {
                this.areaState.height = this.containerSize.height - this.areaState.y;
                if (this.props.ratio) {
                    this.areaState.width = this.areaState.height * this.props.ratio;
                }
            },
        },
        onLeftBottomCornerDrag: {
            runFit: () => {
                if (this.leftSideIsOutOfBounds()) {
                    this.fitAreaState.onLeftBottomCornerDrag.onLeftSideIsOutOfBounds();
                }
                if (this.bottomSideIsOutOfBounds()) {
                    this.fitAreaState.onLeftBottomCornerDrag.onBottomSideIsOutOfBounds();
                }
            },
            onLeftSideIsOutOfBounds: () => {
                const savedX = this.areaState.x;
                this.areaState.x = 0;
                this.areaState.width += savedX;
                if (this.props.ratio) {
                    this.areaState.height = this.areaState.width / this.props.ratio;
                }
            },
            onBottomSideIsOutOfBounds: () => {
                const savedWidth = this.areaState.width;
                this.areaState.height = this.containerSize.height - this.areaState.y;
                if (this.props.ratio) {
                    this.areaState.width = this.areaState.height * this.props.ratio;
                }
                this.areaState.x -= this.areaState.width - savedWidth;
            },
        },
        onRightTopCornerDrag: {
            runFit: () => {
                if (this.rightSideIsOutOfBounds()) {
                    this.fitAreaState.onRightTopCornerDrag.onRightSideIsOutOfBounds();
                }
                if (this.topSideIsOutOfBounds()) {
                    this.fitAreaState.onRightTopCornerDrag.onTopSideIsOutOfBounds();
                }
            },
            onRightSideIsOutOfBounds: () => {
                this.areaState.width = this.containerSize.width - this.areaState.x;
                if (this.props.ratio) {
                    const savedHeight = this.areaState.height;
                    this.areaState.height = this.areaState.width / this.props.ratio;
                    this.areaState.y -= this.areaState.height - savedHeight;
                }
            },
            onTopSideIsOutOfBounds: () => {
                const savedY = this.areaState.y;
                this.areaState.y = 0;
                this.areaState.height += savedY;
                if (this.props.ratio) {
                    this.areaState.width = this.areaState.height * this.props.ratio;
                }
            },
        },
        onLeftTopCornerDrag: {
            runFit: () => {
                if (this.leftSideIsOutOfBounds()) {
                    this.fitAreaState.onLeftTopCornerDrag.onLeftSideIsOutOfBounds();
                }
                if (this.topSideIsOutOfBounds()) {
                    this.fitAreaState.onLeftTopCornerDrag.onTopSideIsOutOfBounds();
                }
            },
            onLeftSideIsOutOfBounds: () => {
                const savedX = this.areaState.x;
                const savedHeight = this.areaState.height;
                this.areaState.x = 0;
                this.areaState.width += savedX;
                if (this.props.ratio) {
                    this.areaState.height = this.areaState.width / this.props.ratio;
                }
                this.areaState.y -= this.areaState.height - savedHeight;
            },
            onTopSideIsOutOfBounds: () => {
                const savedY = this.areaState.y;
                const savedWidth = this.areaState.width;
                this.areaState.y = 0;
                this.areaState.height += savedY;
                if (this.props.ratio) {
                    this.areaState.width = this.areaState.height * this.props.ratio;
                }
                this.areaState.x -= this.areaState.width - savedWidth;
            },
        },
    };
    setAreaStateOnRightBottomCornerDrag = (deltaX, deltaY) => {
        this.areaState.width = this.dragParams.savedAreaStateWidth + deltaX;
        if (this.props.ratio) {
            this.areaState.height = this.areaState.width / this.props.ratio;
        } else {
            this.areaState.height = this.dragParams.savedAreaStateHeight + deltaY;
        }
        this.fitAreaState.onRightBottomCornerDrag.runFit();
    };
    setAreaStateOnRightTopCornerDrag = (deltaX, deltaY) => {
        const savedHeight = this.areaState.height;
        this.areaState.width = this.dragParams.savedAreaStateWidth + deltaX;
        if (this.props.ratio) {
            this.areaState.height = this.areaState.width / this.props.ratio;
            this.areaState.y -= this.areaState.height - savedHeight;
        } else {
            const savedY = this.areaState.y;
            this.areaState.y = this.dragParams.savedAreaStateY + deltaY;
            this.areaState.height += savedY - this.areaState.y;
        }
        this.fitAreaState.onRightTopCornerDrag.runFit();
    };
    setAreaStateOnLeftBottomCornerDrag = (deltaX, deltaY) => {
        this.areaState.x = this.dragParams.savedAreaStateX + deltaX;
        this.areaState.width = this.dragParams.savedAreaStateWidth - deltaX;
        if (this.props.ratio) {
            this.areaState.height = this.areaState.width / this.props.ratio;
        } else {
            this.areaState.height = this.dragParams.savedAreaStateHeight + deltaY;
        }
        this.fitAreaState.onLeftBottomCornerDrag.runFit();
    };
    setAreaStateOnLeftTopCornerDrag = (deltaX, deltaY) => {
        const savedHeight = this.areaState.height;
        this.areaState.x = this.dragParams.savedAreaStateX + deltaX;
        this.areaState.width = this.dragParams.savedAreaStateWidth - deltaX;
        if (this.props.ratio) {
            this.areaState.height = this.areaState.width / this.props.ratio;
            this.areaState.y -= this.areaState.height - savedHeight;
        } else {
            const savedY = this.areaState.y;
            this.areaState.y = this.dragParams.savedAreaStateY + deltaY;
            this.areaState.height += savedY - this.areaState.y;
        }
        this.fitAreaState.onLeftTopCornerDrag.runFit();
    };

    getClientXYOnDragEvents = (event) => {
        let clientX;
        let clientY;

        if (['mousemove', 'mousedown'].includes(event.type)) {
            clientX = event.clientX;
            clientY = event.clientY;
        } else {
            clientX = event.touches[0].clientX;
            clientY = event.touches[0].clientY;
        }

        return { clientX, clientY };
    };

    throttledDrag = throttle((event) => {
        if (!this.setAreaStateFunction) {
            return;
        }

        const { clientX, clientY } = this.getClientXYOnDragEvents(event);

        /** сохраняем текущее состояние чтобы можно было вернуть если выделение выйдет за границы контейнера */
        const savedCurrentAreaState = { ...this.areaState };
        const deltaX = clientX - this.dragParams.clickX;
        const deltaY = clientY - this.dragParams.clickY;

        this.setAreaStateFunction(deltaX, deltaY);

        /** дошли до минимальных значений - сбрасываем состояние (только если менялись размеры) */
        if (
            this.setAreaStateFunction !== this.setAreaStateOnAreaDrag &&
            (Math.ceil(this.areaState.width * this.originalToAreaRatio) < this.props.minimumWidth ||
                Math.ceil(this.areaState.height * this.originalToAreaRatio) < this.props.minimumHeight)
        ) {
            this.areaState = { ...savedCurrentAreaState };
            this.setAreaPosition();
            return;
        }

        /** передаем изменившиеся координаты в роидтельский компонент */
        this.props.onResizeCallback?.({
            noChanges: false,
            relativeSizes: { ...this.areaState },
            absoluteSizes: {
                // округление обязательно в меньшую сторону, чтобы не вылезало за реальные границы картинки
                width: Math.floor(Math.abs(this.areaState.width * this.originalToAreaRatio)),
                height: Math.floor(Math.abs(this.areaState.height * this.originalToAreaRatio)),
                x: Math.floor(Math.abs(this.areaState.x * this.originalToAreaRatio)),
                y: Math.floor(Math.abs(this.areaState.y * this.originalToAreaRatio)),
            },
            originalToAreaRatio: this.originalToAreaRatio,
        });

        this.setAreaPosition();
    }, MOUSEMOVE_THROTTLE_DELAY_MS);

    setAreaStateFunctionOnStartLeftTopCornerDrag = (event) => {
        this.setAreaStateFunction = this.setAreaStateOnLeftTopCornerDrag;
        this.setXYOnDragStart(event);
    };
    setAreaStateFunctionOnStartRightTopCornerDrag = (event) => {
        this.setAreaStateFunction = this.setAreaStateOnRightTopCornerDrag;
        this.setXYOnDragStart(event);
    };
    setAreaStateFunctionOnStartRightBottomCornerDrag = (event) => {
        this.setAreaStateFunction = this.setAreaStateOnRightBottomCornerDrag;
        this.setXYOnDragStart(event);
    };
    setAreaStateFunctionOnStartLeftBottomCornerDrag = (event) => {
        this.setAreaStateFunction = this.setAreaStateOnLeftBottomCornerDrag;
        this.setXYOnDragStart(event);
    };
    setAreaStateFunctionOnStartAreaDrag = (event) => {
        this.setAreaStateFunction = this.setAreaStateOnAreaDrag;
        this.setXYOnDragStart(event);
    };

    render() {
        return (
            <div className="image-crop" ref={this.containerElement}>
                <img
                    ref={this.imageElement}
                    src={this.props.src}
                    onLoad={this.imageOnLoad}
                    className="image-crop__image"
                />
                <div className="image-crop__area" ref={this.areaElement}>
                    <div className="image-crop__area-inner">
                        <div
                            onMouseDown={this.setAreaStateFunctionOnStartLeftTopCornerDrag}
                            onTouchStart={this.setAreaStateFunctionOnStartLeftTopCornerDrag}
                            className="image-crop__area-point image-crop__area-point_left-top"
                        />
                        <div
                            onMouseDown={this.setAreaStateFunctionOnStartRightTopCornerDrag}
                            onTouchStart={this.setAreaStateFunctionOnStartRightTopCornerDrag}
                            className="image-crop__area-point image-crop__area-point_right-top"
                        />
                        <div
                            onMouseDown={this.setAreaStateFunctionOnStartRightBottomCornerDrag}
                            onTouchStart={this.setAreaStateFunctionOnStartRightBottomCornerDrag}
                            className="image-crop__area-point image-crop__area-point_right-bottom"
                        />
                        <div
                            onMouseDown={this.setAreaStateFunctionOnStartLeftBottomCornerDrag}
                            onTouchStart={this.setAreaStateFunctionOnStartLeftBottomCornerDrag}
                            className="image-crop__area-point image-crop__area-point_left-bottom"
                        />
                        <div
                            onMouseDown={this.setAreaStateFunctionOnStartAreaDrag}
                            onTouchStart={this.setAreaStateFunctionOnStartAreaDrag}
                            className="image-crop__area-drag-zone"
                        />
                    </div>
                </div>
            </div>
        );
    }
}
